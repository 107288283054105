<template>
  <div class="upload-icon">
    <vue-dropzone
      id="upload"
      ref="upload"
      :options="dropzoneOptions"
      :destroyDropzone="false"
      @vdropzone-sending="customEvent"
      @vdropzone-removed-file="handleRemove"
      @vdropzone-success="handleSuccess"
    />
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import IconService from "../../services/icon.services";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { vueDropzone },
  computed: {
    ...mapGetters("landing", ["icons"]),
  },
  data() {
    return {
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "icons";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
        destroyDropzone: false,
      },
    };
  },
  async mounted() {
    await this.getIcons();
    this.mountItems(this.icons);
  },
  methods: {
    ...mapActions("landing", ["getIcons"]),
    mountItems(icons) {
      if (this.$refs.upload) {
        icons.data.forEach((item) => {
            if(!item.is_default){
                let mockFile = {
                    accepted: true,
                    kind: "image",
                    dataURL: item.url,
                    ...item,
                };
                this.$refs.upload.manuallyAddFile(mockFile, item.url);
                this.$refs.upload.dropzone.emit("thumbnail", mockFile, item.url);
                this.$refs.upload.dropzone.emit("complete", mockFile);
            }
        });
      }
    },
    async handleSuccess(file) {
      const response = JSON.parse(file.xhr.response);
      this.$refs.upload.removeFile(file);
      let mockFile = {
        accepted: true,
        kind: "image",
        dataURL: response.file_url,
      };

      this.$refs.upload.manuallyAddFile(mockFile, response.file_url);
      this.$refs.upload.dropzone.emit("thumbnail", mockFile, response.file_url);
      this.$refs.upload.dropzone.emit("complete", mockFile);
      this.getIcons();
    },
    async handleRemove(file) {
        await IconService.deleteIcon({ url: file.dataURL });

    },
    async customEvent(file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("name", file.name);
    },
  },
};
</script>

<style lang="scss">
.upload-icon {
  & .dropzone {
    & .dz-preview {
      & .dz-image {
        & img {
          width: 75px;
          height: 75px;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
